import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Smarkio Chatbot: theme similar to byside livechat`}</li>
      <li parentName="ul">{`Bug Fix: Able to use dynamic fields (Specifically URL) as lines on aggregation panels.`}</li>
      <li parentName="ul">{`Bug Fix: Now we have the same results across all products and that Contact-Email metrics gets the same results.`}</li>
      <li parentName="ul">{`Bug Fix: Accounts users can share between the team.`}</li>
      <li parentName="ul">{`Bug Fix: Fixed the placeholder name.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      